import dayjs from 'dayjs';
import { z } from 'zod';

import { validatePermission } from 'app/helpers/CommerceItem';
import { PaymentOptionType, ReviewStatusType, SurveyOption } from 'app/models/CommerceItem';
import { FormPreviewItem, PublishStatusType } from 'app/models/Common';
import { DriveType, FolderType } from 'app/models/Drive';

import { COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA, COMMERCE_ITEM_PERMISSION_FORM_SCHEMA } from './CommerceItem';

export const PRODUCT_BASIC_INFO_FORM_SCHEMA = z
  .object({
    title: z
      .string({
        required_error: '必填',
      })
      .min(1, '必填')
      .max(200, '超出字數上限'),
    shopId: z
      .number({
        required_error: '必選',
      })
      .nullable()
      .refine((val) => val && val > 0, { message: '必選' }),
    commerceCategory: z
      .number({
        required_error: '必選',
      })
      .nullable()
      .refine((val) => val && val > 0, { message: '必選' }),
    CommerceItemHashTags: z
      .array(
        z.object({
          commerceItemHashTagId: z
            .number().optional(),
          hashTag: z
            .string()
            .min(1, '必填'),
        }),
      ),
    coverPhoto: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
        {
          required_error: '必填',
        },
      )
      .min(1, '必填'),
    images: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
        {
          required_error: '必填',
        },
      ),
    referenceCode: z
      .string(),
    contactPerson: z
      .string(),
    contactPhoneNumber: z
      .string(),
    subtitle: z
      .string()
      .max(200, '超出字數上限'),
    description: z
      .string({
        required_error: '必填',
      }).min(1, '必填'),
    caution: z
      .string(),
    paidReminder: z
      .string(),
    attachment: z
      .object({
        path: z
          .string(),
        folderType: z
          .custom<FolderType>(),
        driveType: z
          .custom<DriveType>(),
        hiddenRoot: z
          .string(),
      }).nullable(),
    surveyId: z
      .number()
      .nullable(),
    publishStatus: z
      .custom<PublishStatusType>(),
    publishedDate: z
      .date()
      .nullable(),
    publishEndDate: z
      .date()
      .nullable(),
    discontinueDate: z
      .date()
      .nullable(),
    redemptionDueDate: z
      .date()
      .nullable(),
    openForSaleDate: z
      .date()
      .nullable(),

  })
  .refine(
    ({ publishStatus, publishedDate }) => publishStatus === 'Published' || publishedDate,
    { message: '請選擇發佈時間', path: ['publishedDate'] },
  )
  .refine(({ openForSaleDate, discontinueDate }) => !openForSaleDate
  || !discontinueDate
  || dayjs(openForSaleDate).isSameOrBefore(dayjs(discontinueDate)), {
    message: '結束時間不能早於開始時間',
    path: ['discontinueDate'],
  })
  .refine(
    ({ publishStatus, publishedDate, publishEndDate }) => !publishEndDate
    || dayjs(publishStatus === 'Unpublished' ? publishedDate || undefined : undefined).isSameOrBefore(dayjs(publishEndDate)),
    { message: '結束發佈時間不能比開始發佈時間早', path: ['publishEndDate'] },
  )
  .refine(
    ({ publishEndDate, discontinueDate }) => !publishEndDate
    || !discontinueDate
    || dayjs(discontinueDate).isSameOrBefore(publishEndDate),
    { message: '完售日期必須早於自動下架時間', path: ['discontinueDate'] },
  );

export type ProductBasicInfoFormDataType = z.infer<typeof PRODUCT_BASIC_INFO_FORM_SCHEMA>;

export const INITIALIZED_PRODUCT_BASIC_INFO : ProductBasicInfoFormDataType = {
  title: '',
  CommerceItemHashTags: [],
  coverPhoto: [],
  images: [],
  subtitle: '',
  description: '',
  referenceCode: '',
  contactPerson: '',
  contactPhoneNumber: '',
  caution: '',
  paidReminder: '',
  attachment: null,
  surveyId: null,
  publishStatus: 'Published',
  publishedDate: null,
  publishEndDate: null,
  shopId: null,
  commerceCategory: null,
  discontinueDate: null,
  redemptionDueDate: null,
  openForSaleDate: null,
};

export const PRODUCT_PAYMENT_FORM_SCHEMA = z
  .object({
    paymentOptions: z.array(
      z.object({
        commercePurchaseOptionId: z
          .number()
          .optional(),
        commercePurchaseOptionTitle: z
          .string({
            required_error: '必填',
          })
          .max(200, '超出字數上限'),
        commercePurchaseOptionSubtitle: z
          .string({
            required_error: '必填',
          })
          .max(200, '超出字數上限'),
        image: z
          .array(
            z.instanceof(File)
              .or(z.custom<FormPreviewItem>()),
          ),
        paymentType: z
          .custom<PaymentOptionType>(),
        cash: z
          .string(),
        point: z
          .string(),
        commercePurchaseOptionQuota: z
          .string(),
        commercePurchaseOptionPerUserMin: z
          .string()
          .regex(/^\d*$/, '請輸入正整數')
          .or(z.literal('')),
        commercePurchaseOptionPerUserMax: z
          .string()
          .regex(/^[1-9]\d*$/, '請輸入正整數')
          .or(z.literal('')),
        commercePurchaseOptionMin: z
          .string()
          .regex(/^\d*$/, '請輸入正整數')
          .or(z.literal('')),
        commercePurchaseOptionMax: z
          .string()
          .regex(/^[1-9]\d*$/, '請輸入正整數')
          .or(z.literal('')),
        pointEarnAfterApply: z
          .string()
          .refine((val) => !val || Number(val) > 0, '請輸入正數'),
        pointEarnAfterCheckIn: z
          .string()
          .refine((val) => !val || Number(val) > 0, '請輸入正數'),
        allowGuest: z
          .boolean(),
        isAllowWaitList: z
          .boolean(),
        waitListQuota: z
          .string()
          .regex(/^[1-9]\d*$/, '請輸入正整數')
          .or(z.literal('')),
        surveyOption: z
          .custom<SurveyOption>(),
        surveyId: z
          .number()
          .nullable(),
      })
        .refine(({ waitListQuota, isAllowWaitList }) => !isAllowWaitList || waitListQuota, {
          message: '必填',
          path: ['waitListQuota'],
        })
        .refine(({ paymentType, cash }) => !(paymentType === 'cash' || paymentType === 'cashAndPoint') || Number(cash) >= 4, {
          message: '最低金額為HKD$4',
          path: ['cash'],
        })
        .refine(({ paymentType, point }) => !(paymentType === 'point' || paymentType === 'cashAndPoint') || Number(point) > 0, {
          message: '最低積分為1分',
          path: ['point'],
        })
        .refine((
          { commercePurchaseOptionPerUserMin, commercePurchaseOptionPerUserMax },
        ) => !commercePurchaseOptionPerUserMin
              || !commercePurchaseOptionPerUserMax
              || parseInt(commercePurchaseOptionPerUserMin, 10) <= parseInt(commercePurchaseOptionPerUserMax, 10), {
          message: '最少數量不能大於最多數量',
          path: ['commercePurchaseOptionPerUserMin'],
        })
        .refine((
          { commercePurchaseOptionMin, commercePurchaseOptionMax },
        ) => !commercePurchaseOptionMin
              || !commercePurchaseOptionMax
              || parseInt(commercePurchaseOptionMin, 10) <= parseInt(commercePurchaseOptionMax, 10), {
          message: '最少數量不能大於最多數量',
          path: ['commercePurchaseOptionMin'],
        })
        .refine((
          { surveyOption, surveyId },
        ) => surveyOption === 'Unessential' || (surveyId ?? 0) > 0, {
          message: '必選',
          path: ['surveyId'],
        }),
    ),
    isCharity: z
      .boolean(),
    acceptDonation: z
      .boolean(),
    allowShare: z
      .boolean(),
    displayQRcode: z
      .boolean(),
    surveyIdBeforePayment: z
      .number()
      .nullable(),
    perOrderMin: z
      .string()
      .regex(/^\d*$/, '請輸入正整數')
      .or(z.literal('')),
    perOrderMax: z
      .string()
      .regex(/^[1-9]\d*$/, '請輸入正整數')
      .or(z.literal('')),
    perUserTotalMin: z
      .string()
      .regex(/^\d*$/, '請輸入正整數')
      .or(z.literal('')),
    perUserTotalMax: z
      .string()
      .regex(/^[1-9]\d*$/, '請輸入正整數')
      .or(z.literal('')),
    afterPaymentAttachment: z
      .object({
        path: z
          .string(),
        folderType: z
          .custom<FolderType>(),
        driveType: z
          .custom<DriveType>(),
        hiddenRoot: z
          .string(),
      }).nullable(),
  })
  .refine((
    { perOrderMin, perOrderMax },
  ) => !perOrderMin
              || !perOrderMax
              || parseInt(perOrderMin, 10) <= parseInt(perOrderMax, 10), {
    message: '最少數量不能大於最多數量',
    path: ['perOrderMin'],
  })
  .refine(
    (
      { perUserTotalMin, perUserTotalMax },
    ) => !perUserTotalMin
              || !perUserTotalMax
              || parseInt(perUserTotalMin, 10)
    <= parseInt(perUserTotalMax, 10),
    {
      message: '最少數量不能大於最多數量',
      path: ['perUserTotalMin'],
    },
  );

type EventPaymentFormDataType = z.infer<typeof PRODUCT_PAYMENT_FORM_SCHEMA>;

export const INITIALIZED_PRODUCT_PAYMENT_OPTION: EventPaymentFormDataType['paymentOptions'][number] = {
  commercePurchaseOptionId: undefined,
  image: [],
  commercePurchaseOptionTitle: '',
  commercePurchaseOptionSubtitle: '',
  paymentType: 'free',
  cash: '',
  point: '',
  commercePurchaseOptionQuota: '',
  commercePurchaseOptionMin: '',
  commercePurchaseOptionMax: '',
  commercePurchaseOptionPerUserMin: '',
  commercePurchaseOptionPerUserMax: '',
  pointEarnAfterApply: '',
  pointEarnAfterCheckIn: '',
  allowGuest: false,
  isAllowWaitList: false,
  waitListQuota: '',
  surveyOption: 'Unessential',
  surveyId: null,
};

export const INITIALIZE_PRODUCT_PAYMENT: EventPaymentFormDataType = {
  paymentOptions: [INITIALIZED_PRODUCT_PAYMENT_OPTION],
  isCharity: false,
  acceptDonation: false,
  allowShare: true,
  displayQRcode: true,
  perOrderMin: '',
  perOrderMax: '',
  perUserTotalMin: '',
  perUserTotalMax: '',
  surveyIdBeforePayment: null,
  afterPaymentAttachment: null,
};

export const PRODUCT_FORM_SCHEMA = z
  .object({
    reviewStatus: z
      .custom<ReviewStatusType>()
      .optional(),
    remarks: z
      .string(),
    basicInfo: PRODUCT_BASIC_INFO_FORM_SCHEMA,
    payment: PRODUCT_PAYMENT_FORM_SCHEMA,
    permission: COMMERCE_ITEM_PERMISSION_FORM_SCHEMA,
    pushNotification: COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA,
  })
  .superRefine(({
    reviewStatus, permission, payment: { paymentOptions },
  }, ctx) => {
    if (reviewStatus !== '草稿') {
      paymentOptions.forEach((option, index) => {
        if (!option.commercePurchaseOptionTitle) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '必填',
            path: ['payment', 'paymentOptions', index, 'commercePurchaseOptionTitle'],
          });
        }
        if (!option.commercePurchaseOptionQuota) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '必填',
            path: ['payment', 'paymentOptions', index, 'commercePurchaseOptionQuota'],
          });
        } else if (!option.commercePurchaseOptionQuota.match(/^[1-9]\d*$/)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '請輸入正整數',
            path: ['payment', 'paymentOptions', index, 'commercePurchaseOptionQuota'],
          });
        }
      });
      validatePermission(permission, ctx);
    }
  });

export type ProductFormDataType = z.infer<typeof PRODUCT_FORM_SCHEMA>;
