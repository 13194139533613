import { Popover, Transition } from '@headlessui/react';
import {
  ChangeEvent,
  Fragment,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { CiBoxList, CiGrid41 } from 'react-icons/ci';
import { FaPlus } from 'react-icons/fa6';
import { FcFile, FcFolder } from 'react-icons/fc';
import { IoTrashBin } from 'react-icons/io5';
import { MdDriveFileMove } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  createNewFolder, deleteOneFile, FileParams, listFolder, moveRenameDocument, uploadOneFileToDrive,
} from 'app/apis/DriveServices';
import {
  Container,
  DriveBreadCrumb,
  DriveFileItem,
  DriveFolderFormModal,
  DriveFolderItem,
  DriveLeftBar,
  DriveMoveToModal,
  DriveSearchBar,
  NoData,
  UploadFilePopUp,
  VideoModal,
} from 'app/components';
import Button from 'app/components/Button';
import { ImagePreview } from 'app/components/ImagePreview';
import Modal from 'app/components/Modal';
import { PdfPreview } from 'app/components/PdfPreview';
import { DriveFolderFormDataType } from 'app/constants';
import { getDriveFilePreviewLink, isImageFile, isVideoFile } from 'app/helpers/Drive';
import {
  DriveFileResponse, DriveType, FolderType, UploadFileItem,
} from 'app/models/Drive';
import { useAuthStore, useDriveStore, useShopStore } from 'app/stores';
import cn from 'app/utils/ClassName';

export const DrivePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { profile } = useAuthStore();
  const { shop } = useShopStore();
  const { isListView, toggleIsListView } = useDriveStore();

  const [driveData, setDriveData] = useState<DriveFileResponse[]>([]);
  const [selectedDriveData, setSelectedDriveData] = useState<string[]>([]);
  const [, setLoading] = useState<boolean>(false);

  const [pdfPreviewVisible, setPdfPreviewVisible] = useState<boolean>(false);
  const [videoModalVisible, setVideoModalVisible] = useState<boolean>(false);
  const [viewingFile, setViewingFile] = useState<DriveFileResponse | null>(null);
  const [imagePreviewVisible, setImagePreviewVisible] = useState<boolean>(false);
  const [imagePreviewSelectedIndex, setImagePreviewSelectedIndex] = useState<number>(0);
  const [folderFormModalLoading, setFolderFormModalLoading] = useState<boolean>(false);
  const [folderFormModalVisible, setFolderFormModalVisible] = useState<boolean>(false);
  const [moveToModalLoading, setMoveToModalLoading] = useState<boolean>(false);
  const [moveToModalVisible, setMoveToModalVisible] = useState<boolean>(false);

  const [deleteModalLoading, setDeleteModalLoading] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const [, setUploadPopupVisible] = useState<boolean>(false);
  const [, setUploadLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFileItem[]>([]);

  const relatedShopId: number | undefined = useMemo(
    () => shop?.shopId || profile?.shopId || profile?.Department?.shopId || undefined,
    [profile?.Department?.shopId, profile?.shopId, shop?.shopId],
  );

  const images = useMemo(() => driveData.filter((data) => data.type === 'File' && isImageFile(data.fileName))
    .map(({
      folderType: fileFolderType, dir, fileName,
    }) => ({
      preview: getDriveFilePreviewLink(fileFolderType, dir, fileName, relatedShopId || undefined),
      name: fileName,
    })), [driveData, relatedShopId]);

  const currentFolderType: FolderType = useMemo(() => {
    if (searchParams.get('folderType')) {
      return searchParams.get('folderType') as FolderType;
    }
    if (profile?.role === '店主' || profile?.role === '店員' || shop?.fromSV === 'partner') {
      return 'private';
    }
    return 'others';
  }, [profile?.role, searchParams, shop?.fromSV]);

  const getDriveData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await listFolder({
        folderType: currentFolderType,
        driveType: relatedShopId ? 'shop' : 'public',
        shopId: relatedShopId,
        path: searchParams.get('path')
          ? decodeURIComponent(searchParams.get('path') ?? '')
          : undefined,
      });
      setDriveData(() => data);
      setSelectedDriveData([]);
    } catch {
      toast.error('找不到資料夾');
    } finally {
      setLoading(false);
    }
  }, [currentFolderType, relatedShopId, searchParams]);

  useEffect(() => {
    getDriveData();
  }, [getDriveData]);

  const onSelect = ({ dir, fileName }: DriveFileResponse) => {
    const foundIndex = selectedDriveData.indexOf(`${dir}/${fileName}`);
    if (foundIndex === -1) {
      setSelectedDriveData((prev) => [...prev, `${dir}/${fileName}`]);
    } else {
      setSelectedDriveData((prev) => {
        const newSelectedDriveData = prev.filter((data) => data !== `${dir}/${fileName}`);
        return [...newSelectedDriveData];
      });
    }
  };

  const onUploadFiles = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length) {
        const newFiles = Array.from(e.target.files);

        let files: UploadFileItem[] = [...fileList];
        files = files.concat(
          newFiles.map((file) => ({
            file,
            name: file.name,
            type: currentFolderType,
            status: 'uploading',
          })),
        );
        setFileList(files);
        setUploadPopupVisible(true);
        setUploadLoading(true);

        await Promise.all(files.map(async (file, i) => {
          if (files[i].status !== 'success') {
            const formData = new FormData();
            formData.append(
              'file',
              file.file,
              encodeURIComponent(file.name),
            );
            try {
              await uploadOneFileToDrive(
                {
                  folderType: currentFolderType,
                  driveType: relatedShopId ? 'shop' : 'public',
                  shopId: relatedShopId,
                  path: searchParams.get('path')
                    ? decodeURIComponent(searchParams.get('path') ?? '')
                    : undefined,
                },
                formData,
              );
              files[i].status = 'success';
            } catch (err) {
              files[i].status = 'fail';
            }
            setFileList([...files]);
          }
        }));
      }
      getDriveData();
      setUploadLoading(false);
    },
    [getDriveData, fileList, currentFolderType, relatedShopId, searchParams],
  );

  const folderFormOnsubmit = async ({ name, image }: DriveFolderFormDataType) => {
    setFolderFormModalLoading(true);
    try {
      const path = searchParams.get('path')
        ? decodeURIComponent(searchParams.get('path') ?? '')
        : '';
      if (image?.length && 'size' in image[0] && image[0].size) {
        const formData = new FormData();
        formData.append('file', image[0], `.${encodeURI(name)}`);

        await uploadOneFileToDrive(
          {
            folderType: currentFolderType,
            driveType: relatedShopId ? 'shop' : 'public',
            shopId: relatedShopId,
            path,
          },
          formData,
        );
      }
      await createNewFolder({
        driveType: relatedShopId ? 'shop' : 'public',
        shopId: relatedShopId,
        folderType: currentFolderType,
        path: `${path}/${name}`,
      });
      await getDriveData();
      setFolderFormModalVisible(false);
    } catch {
      toast.error('新增資料夾失敗');
    } finally {
      setFolderFormModalLoading(false);
    }
  };

  const deleteOnConfirm = async () => {
    setDeleteModalLoading(true);
    try {
      await Promise.all(
        selectedDriveData.map((item) => {
          const params: FileParams = {
            folderType: currentFolderType,
            driveType: relatedShopId ? 'shop' : 'public',
            shopId: relatedShopId,
            path: item,
          };
          return deleteOneFile(params);
        }),
      );
      setDeleteModalVisible(false);
      setSelectedDriveData([]);
      toast.success('刪除成功。');
      await getDriveData();
    } catch (err) {
      toast.error('刪除失敗，請重試。');
    } finally {
      setDeleteModalLoading(false);
    }
  };

  const onMoveFilesToNewDir = async (newDir: FileParams) => {
    setMoveToModalLoading(true);

    try {
      await Promise.all(
        selectedDriveData.map((item) => {
          const source = {
            folderType: currentFolderType,
            driveType: relatedShopId ? 'shop' : 'public' as DriveType,
            shopId: relatedShopId,
            path: item,
          };
          const dest = {
            ...newDir,
            shopId: relatedShopId,
            path: `${newDir.path}/${item.split('/').at(-1)}`,
          };
          const params = {
            source,
            dest,
          };
          return moveRenameDocument(params);
        }),
      );
      setMoveToModalVisible(false);
      setSelectedDriveData([]);
      toast.success('移動成功');
      getDriveData();
    } catch (err) {
      toast.error('移動失敗，請重試。');
    } finally {
      setMoveToModalLoading(false);
    }
  };

  return (
    <div className="flex w-full">
      <DriveLeftBar
        currentFolderType={currentFolderType}
        itemOnClick={(type) => {
          const params = new URLSearchParams();
          params.set('folderType', type);
          setSearchParams(params);
        }}
      />

      <Container containerClassName="flex-[4_4_0%]" className="py-[20px]">
        <DriveBreadCrumb folderType={currentFolderType} path={searchParams.get('path') ?? ''} />
        <div className="flex items-center justify-between px-[10px] pb-[20px]">
          <DriveSearchBar shopId={relatedShopId} currentFolderType={currentFolderType} />

          <div className="flex items-center gap-[8px]">
            {selectedDriveData.length > 0 ? (
              <>
                <Button
                  variant="text"
                  color="attention"
                  containerStyle="rounded-full size-[38px] flex items-center justify-center"
                  onClick={() => { setDeleteModalVisible(true); }}
                >
                  <IoTrashBin className="size-[24px] text-brand-attention" />
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  containerStyle="rounded-full size-[38px] flex items-center justify-center"
                  onClick={() => { setMoveToModalVisible(true); }}
                >
                  <MdDriveFileMove className="size-[24px]" />
                </Button>
              </>
            ) : null}
            <Button
              variant="text"
              color="primary"
              containerStyle="rounded-full min-h-[38px] min-w-[38px] px-[4px] shrink-0"
              onClick={() => {
                if (driveData.length === 0 || driveData.length !== selectedDriveData.length) {
                  setSelectedDriveData(driveData.map((data) => `${data.dir}/${data.fileName}`));
                } else {
                  setSelectedDriveData([]);
                }
              }}
            >
              {driveData.length === 0 || driveData.length !== selectedDriveData.length ? '全選' : '取消全選'}
            </Button>
            <Button
              variant="text"
              color="primary"
              containerStyle="rounded-full size-[38px] flex items-center justify-center"
              onClick={() => { toggleIsListView(); }}
            >
              {isListView
                ? <CiGrid41 className="size-[24px]" />
                : <CiBoxList className="size-[24px]" />}
            </Button>
            <Popover className="relative">
              <Popover.Button
                as={Button}
                variant="text"
                color="primary"
                containerStyle="flex size-[38px] items-center justify-center rounded-full"
              >
                <FaPlus />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 top-[48px] z-[5] min-w-[200px] bg-white shadow-card">
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[10px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={() => { setFolderFormModalVisible(true); }}
                  >
                    <FcFolder />
                    <div className="text-text-primary">資料夾</div>
                  </Button>

                  <input
                    type="file"
                    id="uploadFiles"
                    onChange={onUploadFiles}
                    accept="*"
                    className="hidden"
                    multiple
                  />
                  <label htmlFor="uploadFiles" className="flex w-full cursor-pointer items-center gap-[8px] p-[10px] text-left text-text-primary hover:bg-brand-primary/10">
                    <FcFile />
                    上載檔案
                  </label>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        </div>

        {driveData.length ? (
          <div className={cn('overflow-y-auto px-[10px] h-full', isListView ? 'flex flex-col' : 'grid grid-cols-3 gap-[8px] auto-rows-min')}>
            {driveData.filter((data) => data.type === 'Folder').map((data) => (
              <DriveFolderItem
                data={data}
                key={`${data.dir}/${data.fileName}`}
                isSelected={selectedDriveData.includes(`${data.dir}/${data.fileName}`)}
                isListView={isListView}
                shopId={relatedShopId}
                itemOnClick={(e) => {
                  if (e.detail === 1) {
                    onSelect(data);
                  } else {
                    searchParams.set('path', data.dir
                      ? `${data.dir.split('/').map((item) => encodeURIComponent(item)).join('/')}/${encodeURIComponent(data.fileName)}`
                      : encodeURIComponent(data.fileName));
                    setSearchParams(searchParams);
                  }
                }}
                onFolderOpen={() => {
                  searchParams.set('path', encodeURIComponent(data.dir)
                    ? `${encodeURIComponent(data.dir)}/${encodeURIComponent(data.fileName)}`
                    : encodeURIComponent(data.fileName));
                  setSearchParams(searchParams);
                }}
                getDriveData={getDriveData}
              />
            ))}
            {driveData.filter((data) => data.type === 'File').map((data) => (
              <DriveFileItem
                data={data}
                key={`${data.dir}/${data.fileName}`}
                isSelected={selectedDriveData.includes(`${data.dir}/${data.fileName}`)}
                isListView={isListView}
                shopId={relatedShopId}
                itemOnClick={(e) => {
                  if (e.detail === 1) {
                    onSelect(data);
                  } else if (isImageFile(data.fileName)) {
                    setImagePreviewSelectedIndex(images.findIndex(({ name }) => name === data.fileName) ?? 0);
                    setImagePreviewVisible(true);
                  } else if (isVideoFile(data.fileName)) {
                    setViewingFile(() => data);
                    setVideoModalVisible(true);
                  } else {
                    setViewingFile(() => data);
                    setPdfPreviewVisible(true);
                  }
                }}
                onPreview={() => {
                  if (isImageFile(data.fileName)) {
                    setImagePreviewSelectedIndex(images.findIndex(({ name }) => name === data.fileName) ?? 0);
                    setImagePreviewVisible(true);
                  } else if (isVideoFile(data.fileName)) {
                    setViewingFile(() => data);
                    setVideoModalVisible(true);
                  } else {
                    setViewingFile(() => data);
                    setPdfPreviewVisible(true);
                  }
                }}
                getDriveData={getDriveData}
              />
            ))}
          </div>
        )
          : (
            <div className="flex size-full items-center justify-center">
              <NoData pageName="資料夾" />
            </div>
          )}
      </Container>
      <ImagePreview
        visible={imagePreviewVisible}
        initialIndex={imagePreviewSelectedIndex}
        images={images}
        onClose={() => { setImagePreviewVisible(false); }}
      />
      <VideoModal
        visible={videoModalVisible}
        data={videoModalVisible ? viewingFile : null}
        shopId={relatedShopId}
        onClose={() => {
          setVideoModalVisible(false);
          setViewingFile(null);
        }}
      />
      <PdfPreview
        visible={pdfPreviewVisible}
        shopId={shop?.shopId}
        data={pdfPreviewVisible ? viewingFile : null}
        onClose={() => {
          setPdfPreviewVisible(false);
          setViewingFile(null);
        }}
      />
      <DriveFolderFormModal
        visible={folderFormModalVisible}
        loading={folderFormModalLoading}
        onClose={() => { setFolderFormModalVisible(false); }}
        onSubmit={folderFormOnsubmit}
      />
      <DriveMoveToModal
        visible={moveToModalVisible}
        loading={moveToModalLoading}
        shopId={relatedShopId}
        onClose={() => { setMoveToModalVisible(false); }}
        onSubmit={(data) => { onMoveFilesToNewDir(data); }}
      />
      <Modal
        title="刪除文件"
        visible={deleteModalVisible}
        loading={deleteModalLoading}
        onClose={() => { setDeleteModalVisible(false); }}
        submitOnClick={deleteOnConfirm}
        panelClassName="h-fit relative"
        containerClassName="size-full"
        size="lg"
      >
        確定要刪除已選取之文件嗎?
      </Modal>
      <UploadFilePopUp files={fileList} onClose={() => { setFileList([]); }} />
    </div>
  );
};
